<!--
 * @Description: 人工智能教育
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-07-30 11:52:37
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-10-14 15:32:10
-->
<template>
    <div class="flex-col bg-f5f7fc">  
        <header-nav />
        <div class="container-full bg-e5e9f3 p-r">
            <div class="container-1440">
                <div class="container flex-col text-left h-600">
                    <span class="fc-main fs-48 mt-120"><span class="fc-primary">技术变革</span>推动AI教育落地</span>
                    <span class="w-612 fs-18 mt-30 fc-sub">红熊AI多模态大模型为师生学术科研进行赋能，教学资源高效利用、人才资源充分挖掘，组织资产价值释放</span>
                </div>
            </div>
        </div>
        <div class="container-full mt-80 ">
            <div class="container flex-col">
                <span class="fs-36 fc-main"><span class="fc-primary">AI教育</span>大模型背景</span>
                <span class="plr-100 fc-sub fs-20-thin mt-20">随着人工智能技术的进步，AI已成为教育和创作的新领域。这种技术利用深度学习，可以模仿甚至创造出具有更高价值的作品。在教育领域，它不仅为学生提供了一个实验和学习技巧的平台，还激发了对新教育形式的探索。</span>
                <div class="flex-row justify-between mt-40">
                    <div class="flex-col edu-2-box text-left">
                        <img src="https://images.wujingsc.com/aiModel/website/edu-1%402x.png" class="icon-90" />
                        <span class="edu-title fs-24-fat">AI高校行</span>
                        <div class="flex-col border-t-1 mt-40">
                            <span class="fc-sub-1 fs-18 mt-50 line-h-32">为学生群体提供AIGC知识科普讲座，带领学生了解前沿的AIGC知识，校企之间可以进行科普讲座，课程合作，培养方案设计，产学研用基地等。</span>
                        </div>
                    </div>
                    <div class="flex-col edu-2-box edu-2-box-1 text-left">
                        <img src="https://images.wujingsc.com/aiModel/website/edu-2%402x.png" class="icon-90" />
                        <span class="edu-title fs-24-fat">AI绘画课程</span>
                        <div class="flex-col border-t-1 mt-40">
                            <span class="fc-sub-1 fs-18 mt-50 line-h-32">红熊AI讲师团队研发的AIGC线上课程，3大模块、34课时，带你从入门到精通，掌握AI绘画关键技能。</span>
                        </div>
                    </div>
                    <div class="flex-col edu-2-box edu-2-box-2 text-left">
                        <img src="https://images.wujingsc.com/aiModel/website/edu-3%402x.png" class="icon-90" />
                        <span class="edu-title fs-24-fat">企业培训</span>
                        <div class="flex-col border-t-1 mt-40">
                            <span class="fc-sub-1 fs-18 mt-50 line-h-32">针对企业的AIGC产业应用落地，为企业提供公开分享及内容培训讲座，推动企业进行新一轮的产业升级。</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-full mt-80 edu-3-1">
            <div class="container flex-col">
                <span class="fs-36 mt-80">AI+ 教育应用场景</span>
                <div class="flex-row justify-center mt-40">
                    <div class="edu-3-item border-r-1 flex-col">
                        <span class="fs-24-fat">教</span>
                        <span class="fs-16 mt-5">Teaching</span>
                    </div>
                    <div class="edu-3-item border-r-1 flex-col">
                        <span class="fs-24-fat">学</span>
                        <span class="fs-16 mt-5">Learning</span>
                    </div>
                    <div class="edu-3-item border-r-1 flex-col">
                        <span class="fs-24-fat">练</span>
                        <span class="fs-16 mt-5">Practice</span>
                    </div>
                    <div class="edu-3-item flex-col">
                        <span class="fs-24-fat">测</span>
                        <span class="fs-16 mt-5">Assessment</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-full edu-3-2">
            <div class="container justify-center flex-row-wrap mt-60">
                <span class="edu-3-2-item fs-20-fat fc-main">教学助手</span>
                <span class="edu-3-2-item fs-20-fat fc-main">智能助教/助手</span>
                <span class="edu-3-2-item fs-20-fat fc-main">错题解析</span>
                <span class="edu-3-2-item fs-20-fat fc-main">智能出题</span>
                <span class="edu-3-2-item fs-20-fat fc-main mt-30">数字人</span>
                <span class="edu-3-2-item fs-20-fat fc-main mt-30">知识地图</span>
                <span class="edu-3-2-item fs-20-fat fc-main mt-30">智能报告</span>
                <span class="edu-3-2-item fs-20-fat fc-main mt-30">智能阅卷</span>
            </div>
        </div>
        <div class="container edu-4 p-r">
            <div class="flex-col edu-4-p-a">
                <div class="flex-row justify-center mt-40">
                    <span class="case-tab-title" :class="curItem === 0 ? 'active' : ''" @click="curItem = 0">AI 专家</span>
                    <!-- <span class="case-tab-title" :class="curItem === 1 ? 'active' : ''" @click="curItem = 1">AI 教练</span> -->
                </div>
                <div class="flex-row justify-between mt-60 p-r" v-if="curItem === 0">
                    <div class="flex-col ml-60 text-left">
                        <span class="fs-24-fat fc-primary"># 知识助手</span>
                        <span class="fs-16-fat fc-sub mt-15 w-450">通过自然语言交互，依托企业自有知识库，为员工生成所需知识</span>
                        <span class="fs-24-fat fc-primary mt-60"># 领域专家</span>
                        <span class="fs-16-fat fc-sub mt-15 w-450">模拟特定领域的专家以及真实专家的数字分身，为员工提供个性化知识服务</span>
                        <span class="fs-24-fat fc-primary mt-60"># 创作助理</span>
                        <span class="fs-16-fat fc-sub mt-15 w-450">基于公文模版帮助员工生成报告、摘要、手册等文档，减少人力投入，并保持一致性</span>
                    </div>
                    <img src="https://images.wujingsc.com/aiModel/website/case-1%402x.png" class="img-550" />
                </div>
                <div class="flex-row justify-between mt-60 p-r" v-if="curItem === 1">
                    <div class="flex-col ml-60 text-left">
                        <span class="fs-24-fat fc-primary"># 精准学习</span>
                        <span class="fs-16-fat fc-sub mt-15">基于智能化能力的一对一精准学习和辅导</span>
                        <span class="fs-24-fat fc-primary mt-60"># 以练促学</span>
                        <span class="fs-16-fat fc-sub mt-15">基于知识地图和智能辅导的强化训练</span>
                        <span class="fs-24-fat fc-primary mt-60"># 以学促用</span>
                        <span class="fs-16-fat fc-sub mt-15">AI专家支持用户在工作场景中的知识服务和绩效支持</span>
                    </div>
                    <img src="https://images.wujingsc.com/aiModel/website/ai-lianxi%402x.png" class="img-735" />
                </div>
            </div>
        </div>
        <div class="container flex-col justify-center items-center text-center mt-80">
            <span class="fs-36 fc-main">红熊-<span class="fc-primary">自适应</span>学习系统</span>
            <span class="fs-20-thin w-880 mt-20">深入了解用户需求，灵活调整学习路径，确保每位用户得到个性化辅导。无论是内容、难度还是进度，都能精准匹配，让学习更加高效、愉悦</span>
            <div class="flex-row-wrap mt-40 edu-4 h-600 p-r">
                <div class="flex-col edu-5-item text-left border-r-1 border-b-1">
                    <span class="fs-20-fat">AI打地基</span>
                    <span class="w-160 fc-sub fs-14 mt-50 p-r line-h-20">跨年级跨学段的追根溯源学习，解决学习薄弱点</span>
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-1%402x.png" class="edu-5-icon" />
                </div>
                <div class="flex-col edu-5-item text-left border-r-1 border-b-1">
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-2%402x.png" class="edu-5-icon" />
                    <span class="fs-20-fat">AI微颗粒</span>
                    <span class="fs-20-fat">知识点拆分</span>
                    <span class="w-160 fc-sub fs-14 mt-20  p-r line-h-20">超高清精准定位<br>让知识点更容易学会</span>
                </div>
                <div class="flex-col edu-5-item text-left border-r-1 border-b-1">
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-3%402x.png" class="edu-5-icon" />
                    <span class="fs-20-fat">AI智能诊断</span>
                    <span class="w-160 fc-sub fs-14 mt-50 p-r line-h-20">知识漏洞诊断更精准<br>学习水平情况更全面</span>
                </div>
                <div class="flex-col edu-5-item text-left border-r-1 border-b-1">
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-4%402x.png" class="edu-5-icon" />
                    <span class="fs-20-fat">AI流程式学习</span>
                    <span class="w-160 fc-sub fs-14 mt-50 p-r line-h-20">模仿名师学习思路<br>定制学习内容任务</span>
                </div>
                <div class="flex-col edu-5-item text-left border-b-1">
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-5%402x.png" class="edu-5-icon" />
                    <span class="fs-20-fat">AI错因分析</span>
                    <span class="w-160 fc-sub fs-14 mt-50 p-r line-h-20">大数据探寻错误原因<br>避免重复错误再出现</span>
                </div>

                <div class="flex-col edu-5-item text-left border-r-1">
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-6%402x.png" class="edu-5-icon" />
                    <span class="fs-20-fat">AI MCM<br>思想/能力/方法训练</span>
                    <span class="w-160 fc-sub fs-14 mt-20 p-r line-h-20">智能诊断思想能力方法<br>可定义传授的靶向训练</span>
                </div>
                <div class="flex-col edu-5-item text-left border-r-1">
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-7%402x.png" class="edu-5-icon" />
                    <span class="fs-20-fat">L5级人机互动</span>
                    <span class="w-160 fc-sub fs-14 mt-50 p-r line-h-20">L5级全自动授课AI虚拟老师，沉浸式人机互动学习感受</span>
                </div>
                <div class="flex-col edu-5-item text-left border-r-1">
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-8%402x.png" class="edu-5-icon" />
                    <span class="fs-20-fat">AI报告反馈</span>
                    <span class="w-160 fc-sub fs-14 mt-50 p-r line-h-20">知识学习结果导向<br>学习报告一目了然</span>
                </div>
                <div class="flex-col edu-5-item text-left border-r-1">
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-9%402x.png" class="edu-5-icon" />
                    <span class="fs-20-fat">AI错题本</span>
                    <span class="w-160 fc-sub fs-14 mt-50 p-r line-h-20">创建专属错因知识图谱<br>结合日常学习精准推荐</span>
                </div>
                <div class="flex-col edu-5-item text-left">
                    <img src="https://images.wujingsc.com/aiModel/website/edu-5-10%402x.png" class="edu-5-icon" />
                    <span class="fs-20-fat">AI遗忘复习</span>
                    <span class="w-160 fc-sub fs-14 mt-50 p-r line-h-20">遗忘曲线+知识点特性+学习的数据，定期复习遗忘知识点及时巩固</span>
                </div>
            </div>
            <img src="https://images.wujingsc.com/aiModel/website/edu-6-bg%402x.png" class="edu-6" />
        </div>
        <regbox-footer class="mt-160"/>
        <airb-footer class="mt-60"/>
    </div>
</template>
<script>
import headerNav from '@/components/nav.vue'
import airbFooter from '@/components/footer.vue'
import regboxFooter from '@/components/footer-reg.vue'

export default {
    components:{
        headerNav,airbFooter,regboxFooter
    },
    data(){
        return{
            curItem: 0,
        }
    }
}
</script>