/*
 * @Description: 
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-11 13:49:13
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-10-14 15:27:02
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/common.less"
import {IMG_PUBLIC_PATH,AIPRB_IMG_PATH} from './utils/constants.js'



const app = createApp(App)


app.config.globalProperties.$IMG_PUBLIC_PATH = IMG_PUBLIC_PATH
app.config.globalProperties.$AIPRB_IMG_PATH = AIPRB_IMG_PATH
app.use(store)
app.use(router)
app.mount('#app')
