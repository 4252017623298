<!--
 * @Description: 立即注册 -- 尾部文件
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-13 08:37:10
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-06-21 10:57:00
-->
<template>
<div class="regbox-full">
    <div class="reg-bg"></div>
    <img :src="$IMG_PUBLIC_PATH + 'website/logo-shading-big%402x.png'" class="bg-img"/>
    <img :src="$IMG_PUBLIC_PATH + 'website/reg-foot-bg.png'" class="reg-foot-bg"/>
    <div class="container reg-container flex-col items-center justify-center">
        
        <span class="fs-36">准备好开始探索</span>
        <span class="fs-36"> 生成式 AI 的无限可能</span>
        <a href="https://ai.aiprb.com/signin" target="_blank" class="primary mt-50">立即注册</a>
    </div>
</div>
</template>
<script>
export default {
    name:'regboxFooter',
    data(){
        return {

        }
    }
}
</script>