<!--
 * @Description: 加载服务，协议 等 docs
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-20 13:53:37
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-06-20 14:52:37
-->
<template>
    <div class="flex-col bg-white">
        <header-nav />
        <div class="container ptb-20">
            <div v-html="htmlContent"></div>
        </div>
        <!-- <div class="container h-full-1800">
            <iframe :src="docs" width="100%" height="100%" frameborder="0"></iframe>
        </div> -->

        <!-- <airb-footer class="mt-60"/> -->
    </div>
</template>
<script>
import headerNav from '@/components/nav.vue'
import airbFooter from '@/components/footer.vue'
export default {
    components:{
        headerNav, airbFooter
    },
    data(){
        return{
            docs:'',
            htmlContent:'',
            serviceDocs:'https://images.wujingsc.com/aiModel/website/service.html',
            privacyDOcs:'https://images.wujingsc.com/aiModel/website/privacy.html'
        }
    },
    mounted(){
        if(this.$route.query.name === 'service'){
            this.docs = this.serviceDocs
        }else{
            this.docs = this.privacyDOcs
        }
        this.loadHtmlFile()
    },
    methods:{
        loadHtmlFile(){
            this.htmlContent = ""
            let xhr = new XMLHttpRequest()
            xhr.open('GET',this.docs,false)
            xhr.overrideMimeType("text/html;charset=utf-8")
            xhr.send(null)
            this.htmlContent = xhr.responseText
        }
    }
}
</script>
